import React, { FC } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import useMatchMedia from 'hooks/useMatchMedia';
import { TABLET_BREAKPOINT } from 'utils/constants';

import { ICommunityBannerProps } from './model';

import './CommunityBanner.scss';

const CommunityBanner: FC<ICommunityBannerProps> = ({ banner }) => {
  const {
    title,
    backgroundImage,
    backgroundImageMobile,
    backgroundImageAlt,
    experiences,
    experiencesTitle,
    scrollText,
    scrollIcon,
    scrollIconAlt,
  } = banner?.[0]?.properties || {};

  const isDesktop = useMatchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`);

  return (
    <section className="community-banner">
      <div className="community-banner__content-holder">
        {experiences?.length ? (
          <p className="community-banner__experiences">
            {experiences?.split('').map((number, idx) => (
              <span key={number + idx} className="community-banner__experiences--number">
                {number}
              </span>
            ))}
          </p>
        ) : null}
        <DangerouslySetInnerHtml className="community-banner__title" html={title} />
        <DangerouslySetInnerHtml
          className="community-banner__experiences-title"
          html={experiencesTitle}
        />
      </div>

      {isDesktop ? (
        <div className="community-banner__scroll">
          <p className="community-banner__scroll-text">{scrollText}</p>
          <UniversalImage
            wrapperClassName="community-banner__scroll-icon"
            img={scrollIcon}
            imageAlt={scrollIconAlt}
          />
        </div>
      ) : null}
      <UniversalImage
        wrapperClassName="community-banner__image"
        img={isDesktop ? backgroundImage : backgroundImageMobile}
        imageAlt={backgroundImageAlt}
      />
    </section>
  );
};

export const query = graphql`
  fragment FragmentCommunityBanner on TCommunityBanner {
    properties {
      title
      backgroundImageMobile {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 992, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      backgroundImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 2200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      backgroundImageAlt
      experiences
      experiencesTitle
      scrollText
      scrollIcon {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fixed(width: 20, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        svg {
          content
        }
      }
      scrollIconAlt
    }
  }
`;

export default CommunityBanner;
