import React, { FC, useState, useEffect } from 'react';

import { ICommunityHeaderCounterProps } from '../../model';
import { UPDATE_COUNTER_EVENT_NAME } from '../../../constants';

const CommunityHeaderCounter: FC<ICommunityHeaderCounterProps> = ({ counterLabel }) => {
  const [count, setCount] = useState(() => new Set());

  const number = `${count.size}`.padStart(3, '0').split('');

  useEffect(() => {
    function updateCountHandler(e) {
      setCount((prevSet) => new Set(prevSet.add(e.detail.id)));
    }

    document.addEventListener(UPDATE_COUNTER_EVENT_NAME, updateCountHandler);

    return () => {
      document.removeEventListener(UPDATE_COUNTER_EVENT_NAME, updateCountHandler);
    };
  }, []);

  return count.size ? (
    <div className="community-header__counter">
      <p className="community-header__counter-numbers">
        {number.map((num) => (
          <span className="community-header__counter-number">{num}</span>
        ))}
      </p>
      {counterLabel ? <p className="community-header__counter-text">{counterLabel}</p> : null}
    </div>
  ) : null;
};

export default CommunityHeaderCounter;
