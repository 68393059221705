import React, { FC } from 'react';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import { ICommunityCardProps } from './model';

import './CommunityCard.scss';

const CommunityCard: FC<ICommunityCardProps> = ({
  type,
  title,
  description,
  image,
  imageAlt,
  imageType,
  label,
  viewCtaLabel,
  cardStateClassName,
  isDisabled,
  handleExperienceViewClick,
  ...rest
}) => {
  return (
    <div
      className={classnames('community-card', {
        [`community-card--${type}`]: type,
        [`community-card--${type}-${cardStateClassName}`]: cardStateClassName,
      })}
      {...rest}
    >
      <div
        className={classnames('community-card__image', {
          [`community-card__image--${imageType}`]: imageType,
        })}
      >
        <UniversalImage img={image} imageAlt={imageAlt} objectFitData={{ objectFit: 'contain' }} />
        {label ? <span className="community-card__label">{label}</span> : null}
      </div>

      <div className="community-card__text">
        <DangerouslySetInnerHtml className="community-card__title" html={title} />
        <DangerouslySetInnerHtml className="community-card__description" html={description} />
        <button
          type="button"
          className="community-card__cta"
          aria-label={viewCtaLabel}
          onClick={handleExperienceViewClick}
          disabled={isDisabled}
        >
          {viewCtaLabel}
        </button>
      </div>
    </div>
  );
};

export default CommunityCard;
