import React, { FC, useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';

import IconCustom from 'components/common/IconCustom';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ICommunityExperienceViewerProps } from './model';

import { EXPERIENCE_VIEW_EVENT_NAME, STACK, GRID } from '../constants';

import './CommunityExperienceViewer.scss';

const CommunityExperienceViewer: FC<ICommunityExperienceViewerProps> = ({
  stackExperiences,
  gridExperiences,
  backCTALabel,
  disclaimer,
}) => {
  const [showViewer, setShowViewer] = useState(false);
  const [experienceToShow, setExperienceToShow] = useState<CommunityCard.ICommunityCard>();

  const viewerRef = useRef<HTMLDivElement>(null);
  const currentActiveElement = useRef<HTMLElement | null>(null);

  const experiences = {
    [STACK]: stackExperiences,
    [GRID]: gridExperiences,
  };

  const handleViewerClose = useCallback(() => {
    setShowViewer(false);
  }, []);

  useEffect(() => {
    function experienceViewEventHandler(e) {
      const [type, idx] = (e.detail.id || '').split('-');
      const experience = experiences[type]?.[idx];
      if (experience) {
        setShowViewer(true);
        setExperienceToShow(experience);
      }
    }

    document.addEventListener(EXPERIENCE_VIEW_EVENT_NAME, experienceViewEventHandler);

    return () => {
      document.removeEventListener(EXPERIENCE_VIEW_EVENT_NAME, experienceViewEventHandler);
    };
  }, []);

  useLayoutEffect(() => {
    document.body.style.overflow = showViewer ? 'hidden' : '';
    viewerRef.current?.setAttribute('tabindex', showViewer ? '0' : '-1');

    if (experienceToShow && showViewer) {
      currentActiveElement.current = document.activeElement as HTMLElement;
      viewerRef.current?.focus();
    } else {
      currentActiveElement.current?.focus();
    }
  }, [showViewer]);

  const { image, imageAlt, title, description, label } = experienceToShow?.properties || {};

  return experienceToShow && showViewer ? (
    <div className="experience-viewer" tabIndex={-1} ref={viewerRef}>
      <div className="experience-viewer__container">
        <div className="experience-viewer__container-header">
          <button
            type="button"
            className="experience-viewer__close-btn"
            onClick={handleViewerClose}
          >
            <IconCustom icon="arrow_forward_ios" />
            {backCTALabel}
          </button>
        </div>

        <div className="experience-viewer__content">
          <div className="experience-viewer__visual">
            <div className="experience-viewer__image">
              <UniversalImage img={image} imageAlt={imageAlt} />
            </div>
            <DangerouslySetInnerHtml className="experience-viewer__disclaimer" html={disclaimer} />
          </div>
          <div className="experience-viewer__text">
            {label ? <span className="experience-viewer__label">{label}</span> : null}
            <DangerouslySetInnerHtml className="experience-viewer__title" html={title} />
            <DangerouslySetInnerHtml
              className="experience-viewer__description"
              html={description}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CommunityExperienceViewer;
