import { CARDS_STATE_CLASSES, STACK_DIRECTION, INITIAL_STACK_STATE } from './constants';

const getRandomRotatePosition = (idx: number) => (idx % 2 === 0 ? 1 : -1) * (Math.random() * 3 + 1);

const getTransformStyleProperty = (idx: number) =>
  `rotate(${getRandomRotatePosition(idx)}deg) translate(-50%, -50%)`;

const emitCustomEvent = (eventName: string, payload: Record<string, string>) => {
  const event = new CustomEvent(eventName, { detail: payload });
  document.dispatchEvent(event);
};

const splitCardsToColumn = (cards: unknown[], columns: number) => {
  const cardsInColumns = new Array(columns);

  for (let i = 0; i < cards.length; i++) {
    const columnIndex = i % columns;

    if (!cardsInColumns[columnIndex]) {
      cardsInColumns[columnIndex] = [];
    }

    cardsInColumns[columnIndex].push(cards[i]);
  }

  return cardsInColumns;
};

const getCardClassName = (idx: number, stackState: typeof INITIAL_STACK_STATE): string => {
  if (idx === 0) return CARDS_STATE_CLASSES.initial;

  if (stackState.direction === STACK_DIRECTION.up) {
    if (stackState.activeCard === idx) return CARDS_STATE_CLASSES.show;
    if (stackState.activeCard > idx) return CARDS_STATE_CLASSES.seen;
  }

  if (stackState.direction === STACK_DIRECTION.down) {
    if (stackState.prevActiveCard === idx) return CARDS_STATE_CLASSES.remove;
    if (idx < stackState.prevActiveCard) return CARDS_STATE_CLASSES.seen;
  }

  return CARDS_STATE_CLASSES.hidden;
};

export { getTransformStyleProperty, getCardClassName, emitCustomEvent, splitCardsToColumn };
