import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';

import CommunityHeaderCounter from './components/CommunityHeaderCounter';
import { ICommunityHeaderProps } from './model';

import './CommunityHeader.scss';

const CommunityHeader: FC<ICommunityHeaderProps> = ({ header }) => {
  const { logo, logoAlt, headerLogoAriaLabel, counterLabel, hideCounter } = header[0].properties;

  return (
    <header className="community-header">
      <div className="community-header__container">
        <div className="community-header__logo">
          <Link to="/" className="community-header__link" aria-label={headerLogoAriaLabel}>
            <UniversalImage className="community-header__image" img={logo} imageAlt={logoAlt} />
          </Link>
        </div>

        {!hideCounter ? <CommunityHeaderCounter counterLabel={counterLabel} /> : null}
      </div>
    </header>
  );
};

export const query = graphql`
  fragment FragmentCommunityHeader on TCommunityHeader {
    properties {
      logo {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fixed(width: 120) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      logoAlt
      headerLogoAriaLabel
      counterLabel
      hideCounter
    }
  }
`;

export default CommunityHeader;
