import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ICommunityFooterProps } from './model';

import './CommunityFooter.scss';

const CommunityFooter: FC<ICommunityFooterProps> = ({ footer }) => {
  const { title, description, link, footnote } = footer[0].properties;
  const { name, url, target } = link[0];
  const currentYear = new Date().getFullYear();

  return (
    <footer className="community-footer">
      <div className="community-footer__container">
        <div className="community-footer__content">
          <DangerouslySetInnerHtml className="community-footer__title" html={title} />
          <DangerouslySetInnerHtml className="community-footer__description" html={description} />
          <Button className="community-footer__link" link={url} target={target}>
            {name}
          </Button>
        </div>

        <DangerouslySetInnerHtml
          className="community-footer__footnote"
          html={footnote.replace('%currentYear%', `${currentYear}`)}
        />
      </div>
    </footer>
  );
};

export const query = graphql`
  fragment FragmentCommunityFooter on TCommunityFooter {
    properties {
      title
      description
      footnote
      link {
        name
        url
        target
      }
    }
  }
`;

export default CommunityFooter;
