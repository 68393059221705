import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Seo from 'components/common/Seo';
import Footer from 'components/Footer';
import {
  CommunityBanner,
  CommunityHeader,
  CommunityStack,
  CommunityGrid,
  CommunityFooter,
  CommunityExperienceViewer,
  CommunityBackToTop,
} from 'components/Community';
import useMatchMedia from 'hooks/useMatchMedia';
import { TABLET_BREAKPOINT } from 'utils/constants';

import { ICommunityPageData } from './model.d';

const CommunityPage: FC<ICommunityPageData> = ({
  data: { communityPage, siteSettings, pageFooter },
}) => {
  const isMobile = useMatchMedia(`(max-width: ${TABLET_BREAKPOINT}px)`);

  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    header,
    banner,
    stack,
    grid,
    footer,
    sharedFields,
  } = communityPage;
  const { viewCtaLabel, backCTALabel, disclaimer } = sharedFields[0].properties;

  return (
    <div className="community">
      <Seo
        title={seoMetaTitle}
        description={seoMetaDescription}
        keywords={seoMetaKeywords}
        externalHreflangs={seoExternalHreflangs}
        siteSettings={siteSettings}
      />
      <CommunityHeader header={header} />
      <CommunityBanner banner={banner} />
      <CommunityStack
        stack={stack}
        viewCtaLabel={viewCtaLabel}
        ariaLabelPrev={siteSettings.ariaLabelPrev}
        ariaLabelNext={siteSettings.ariaLabelNext}
      />
      {grid?.[0]?.properties?.cards?.length ? (
        <CommunityGrid grid={grid} viewCtaLabel={viewCtaLabel} />
      ) : null}
      <CommunityFooter footer={footer} />
      <Footer data={pageFooter} lang={siteSettings.lang} isMobile={isMobile} />
      <CommunityExperienceViewer
        stackExperiences={stack?.[0]?.properties?.cards}
        gridExperiences={grid?.[0]?.properties?.cards}
        disclaimer={disclaimer}
        backCTALabel={backCTALabel}
      />
      <CommunityBackToTop />
    </div>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!) {
    communityPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      header {
        ...FragmentCommunityHeader
      }
      banner {
        ...FragmentCommunityBanner
      }
      stack {
        ...FragmentCommunityStack
      }
      grid {
        ...FragmentCommunityGrid
      }
      footer {
        ...FragmentCommunityFooter
      }
      sharedFields {
        properties {
          viewCtaLabel
          backCTALabel
          disclaimer
        }
      }
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    pageFooter: footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
  }
`;

export default CommunityPage;
