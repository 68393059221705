import React, { useState, useEffect, useCallback } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import classnames from 'classnames';

import IconCustom from 'components/common/IconCustom';

import { SCROLL_OFFSET_TO_SHOW_BTN } from '../constants';

import './CommunityBackToTop.scss';

const CommunityBackToTop = () => {
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  useEffect(() => {
    const showScrollBtnHandler = () => {
      setShowScrollBtn(window.pageYOffset > SCROLL_OFFSET_TO_SHOW_BTN);
    };

    window.addEventListener('scroll', showScrollBtnHandler);

    return () => {
      window.removeEventListener('scroll', showScrollBtnHandler);
    };
  }, []);

  const handleScrollToTop = useCallback(() => scrollTo('body'), []);

  return (
    <button
      className={classnames('community-back-to-top', {
        'community-back-to-top--opened': showScrollBtn,
      })}
      type="button"
      onClick={handleScrollToTop}
    >
      <IconCustom icon="arrow-anchor" />
      <span className="sr-only">Back to top</span>
    </button>
  );
};

export default CommunityBackToTop;
