import React, { FC, useCallback, useRef, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import { ICommunityStackProps } from './model';

import CommunityCard from '../CommunityCard';
import { getTransformStyleProperty, emitCustomEvent, getCardClassName } from '../utils';
import {
  STACK,
  UPDATE_COUNTER_EVENT_NAME,
  EXPERIENCE_VIEW_EVENT_NAME,
  INITIAL_STACK_STATE,
  STACK_DIRECTION,
  STACK_CARD_SELECTOR,
} from '../constants';

import './CommunityStack.scss';

const CommunityStack: FC<ICommunityStackProps> = ({
  stack,
  viewCtaLabel,
  ariaLabelPrev,
  ariaLabelNext,
}) => {
  const [stackState, setStackState] = useState(INITIAL_STACK_STATE);

  const { title, description, cards, image, imageAlt } = stack[0].properties;
  const cardsCount = cards.length - 1;

  const transformCardValues = useRef(cards.map((_, i) => getTransformStyleProperty(i)));

  const updateCounter = useCallback((id) => {
    emitCustomEvent(UPDATE_COUNTER_EVENT_NAME, { id: `${STACK}-${id}` });
  }, []);

  const handleExperienceViewClick = useCallback(
    (cardId: number) => () => {
      emitCustomEvent(EXPERIENCE_VIEW_EVENT_NAME, { id: `${STACK}-${cardId}` });
    },
    []
  );

  useEffect(() => {
    const cardsToObserve = Array.from(document.getElementsByClassName(STACK_CARD_SELECTOR));

    const cardsObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting, target }) => {
          if (isIntersecting) {
            const { index } = (target as HTMLDivElement)?.dataset;
            updateCounter(index);
          }
        });
      },
      { threshold: 1 }
    );

    cardsToObserve.forEach((card) => {
      cardsObserver.observe(card);
    });

    return () => {
      cardsToObserve.forEach((card) => {
        cardsObserver.unobserve(card);
      });
    };
  }, []);

  const handlePrevCardClick = () => {
    if (stackState.activeCard === 0) return;
    setStackState((prev) => ({
      activeCard: prev.activeCard - 1,
      prevActiveCard: prev.activeCard,
      direction: STACK_DIRECTION.down,
    }));
  };

  const handleNextCardClick = () => {
    if (stackState.activeCard === cardsCount) return;
    setStackState((prev) => ({
      activeCard: prev.activeCard + 1,
      prevActiveCard: prev.activeCard,
      direction: STACK_DIRECTION.up,
    }));
  };

  return (
    <section className="community-stack">
      <div className="community-stack__container">
        <div className="community-stack__header">
          <DangerouslySetInnerHtml className="community-stack__title" html={title} />
          <DangerouslySetInnerHtml className="community-stack__description" html={description} />
          <UniversalImage
            wrapperClassName="community-stack__image"
            img={image}
            imageAlt={imageAlt}
            objectFitData={{ objectFit: 'contain' }}
          />
        </div>

        <div className="community-stack__cards">
          {cards.map(({ properties }, idx) => {
            const cardClass = getCardClassName(idx, stackState);

            return (
              <div
                key={properties.title}
                className={classnames('community-stack__card-holder', {
                  [`community-stack__card-holder--${cardClass}`]: cardClass,
                })}
                {...(idx !== 0 && {
                  style: { transform: transformCardValues.current[idx] },
                })}
              >
                <CommunityCard
                  {...properties}
                  type={STACK}
                  data-index={idx}
                  viewCtaLabel={viewCtaLabel}
                  isDisabled={idx !== stackState.activeCard}
                  cardStateClassName={cardClass}
                  handleExperienceViewClick={handleExperienceViewClick(idx)}
                />
              </div>
            );
          })}
        </div>

        <div className="community-stack__controls-holder">
          <button
            type="button"
            className="community-stack__control-prev"
            aria-label={ariaLabelPrev}
            onClick={handlePrevCardClick}
            disabled={stackState.activeCard === 0}
          >
            <span aria-hidden="true" className="nf-icon-custom icon-arrow_forward_ios" />
          </button>
          <button
            type="button"
            className="community-stack__control-next"
            aria-label={ariaLabelNext}
            onClick={handleNextCardClick}
            disabled={stackState.activeCard === cardsCount}
          >
            <span aria-hidden="true" className="nf-icon-custom icon-arrow_forward_ios" />
          </button>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentCommunityStack on TCommunityStack {
    properties {
      title
      description
      disclaimer
      viewCtaLabel
      backCTALabel
      image {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 460, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      imageAlt
      cards {
        properties {
          title
          description
          label
          image {
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          imageType
        }
      }
    }
  }
`;

export default CommunityStack;
