import React, { FC, useLayoutEffect, useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classnames from 'classnames';

import useMatchMedia from 'hooks/useMatchMedia';
import { LARGE_TABLET_BREAKPOINT } from 'utils/constants';

import { ICommunityGridProps } from './model';

import CommunityCard from '../CommunityCard';
import { emitCustomEvent, splitCardsToColumn } from '../utils';
import {
  GRID,
  GRID_CONTAINER_SELECTOR,
  EXPERIENCE_VIEW_EVENT_NAME,
  UPDATE_COUNTER_EVENT_NAME,
  GRID_CARD_SELECTOR,
} from '../constants';

import './CommunityGrid.scss';

const CommunityGrid: FC<ICommunityGridProps> = ({ grid, viewCtaLabel }) => {
  const { cards, mobileColumnsLimit, desktopColumnsLimit } = grid[0].properties;

  const isDesktop = useMatchMedia(`(min-width: ${LARGE_TABLET_BREAKPOINT}px)`);

  const columns = isDesktop ? desktopColumnsLimit : mobileColumnsLimit;

  const updateCounter = useCallback((id) => {
    emitCustomEvent(UPDATE_COUNTER_EVENT_NAME, { id: `${GRID}-${id}` });
  }, []);

  const handleExperienceViewClick = useCallback(
    (cardId: number) => () => {
      emitCustomEvent(EXPERIENCE_VIEW_EVENT_NAME, { id: `${GRID}-${cardId}` });
    },
    []
  );

  useEffect(() => {
    const cardsToObserve = Array.from(document.getElementsByClassName(GRID_CARD_SELECTOR));

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ intersectionRatio, target }) => {
          if (intersectionRatio > 0.5) {
            const { index } = (target as HTMLDivElement)?.dataset;
            updateCounter(index);
          }
        });
      },
      { threshold: [0.5, 1] }
    );

    cardsToObserve.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      cardsToObserve.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, [isDesktop]);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: GRID_CONTAINER_SELECTOR,
          scrub: true,
          start: 'bottom center',
          end: 'bottom top',
          invalidateOnRefresh: true,
          snap: {
            snapTo: 1,
            duration: 0.7,
            delay: 0.1,
            ease: 'power1.inOut',
          },
        },
      });

      tl.to(GRID_CONTAINER_SELECTOR, {
        scaleX: 0.8,
        yPercent: -0.2,
        borderRadius: '0 0 16px 16px',
        ease: 'power2.out',
      });
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <section className="community-grid">
      <div className="community-grid__container">
        <div
          className={classnames('community-grid__cards', {
            [`community-grid__cards--colum-${columns}`]: columns,
          })}
        >
          {splitCardsToColumn(cards, columns).map((column, idx) => (
            <div className="community-grid__cards-column" key={`column-${idx}`}>
              {column.map(({ properties }) => (
                <CommunityCard
                  key={properties.id}
                  {...properties}
                  type={GRID}
                  data-index={properties.id}
                  viewCtaLabel={viewCtaLabel}
                  handleExperienceViewClick={handleExperienceViewClick(properties.id)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentCommunityGrid on TCommunityGrid {
    properties {
      mobileColumnsLimit
      desktopColumnsLimit
      cards {
        properties {
          id
          title
          description
          label
          image {
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          imageType
        }
      }
    }
  }
`;

export default CommunityGrid;
