const UPDATE_COUNTER_EVENT_NAME = 'updateCounter';
const EXPERIENCE_VIEW_EVENT_NAME = 'experienceView';

const GRID_CONTAINER_SELECTOR = '.community-grid__container';
const GRID_CARD_SELECTOR = 'community-card--grid';
const STACK_CARD_SELECTOR = 'community-card--stack';

const SCROLL_OFFSET_TO_SHOW_BTN = 150;

const GRID = 'grid';
const STACK = 'stack';

const STACK_DIRECTION = {
  up: 'UP',
  down: 'DOWN',
} as const;

const INITIAL_STACK_STATE = {
  activeCard: 0,
  prevActiveCard: 0,
  direction: '',
};

const CARDS_STATE_CLASSES = {
  initial: 'initial',
  seen: 'seen',
  hidden: 'hidden',
  show: 'show',
  remove: 'remove',
};

export {
  GRID,
  STACK,
  GRID_CONTAINER_SELECTOR,
  UPDATE_COUNTER_EVENT_NAME,
  EXPERIENCE_VIEW_EVENT_NAME,
  SCROLL_OFFSET_TO_SHOW_BTN,
  STACK_DIRECTION,
  INITIAL_STACK_STATE,
  CARDS_STATE_CLASSES,
  STACK_CARD_SELECTOR,
  GRID_CARD_SELECTOR,
};
